export const ENV = {
    'NODE_ENV': 'Development',
    'BASE_URL': 'https://opmatters-be-api.codashs.com/api/',
    // 'BASE_URL': 'http://localhost:4100/api/',

}

export const API_URLS = {
    'login': 'login/',
    'signup': 'signup/',
    'formSignup': 'form/signup',
    'formLogin': 'form/login',
    'getAllUsers': 'users/',
    'mailverified': 'mailverified/',
    'dashboardHome': '/dashboard/home',
    'dashboardUsers': '/dashboard/users',

}

